import React from 'react'

const Portfolio = () => {
  return (
    <section className="contact section" id="portfolio">
        <h2 className="section__title">Portfolio</h2>
        <span className="section__subtitle">Personal projects</span>
   
    </section>
  )
}

export default Portfolio
